import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "shared-wisdom---everipedia",
      "style": {
        "position": "relative"
      }
    }}>{`Shared Wisdom - Everipedia`}<a parentName="h1" {...{
        "href": "#shared-wisdom---everipedia",
        "aria-label": "shared wisdom   everipedia permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Selected as the first guild for KB7, the outcome of our conversation about Everipedia was not particularly positive, illustrating that not all of the tokens this guild considers are necessarily well thought-out and clearly designed.`}</p>
    <p>{`One comment in particular seemed to capture the vibe of the group: "It could be an interesting machine to study… if only to understand why it didn’t do what it set out to do". We feel that we can learn just as much (sometimes more!) by looking at token experiments which don't work, as we can by considering models that seem to be living up to their stated design goals.`}</p>
    <h2 {...{
      "id": "preparation",
      "style": {
        "position": "relative"
      }
    }}>{`Preparation`}<a parentName="h2" {...{
        "href": "#preparation",
        "aria-label": "preparation permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <ol>
      <li parentName="ol">{`Browse over `}<a parentName="li" {...{
          "href": "https://github.com/EveripediaNetwork/Everipedia/blob/master/TechnicalWhitepaper.md"
        }}>{`the whitepaper`}</a>{`.`}</li>
      <li parentName="ol">{`Read `}<a parentName="li" {...{
          "href": "https://cointelegraph.com/news/everipedia-iq-rallies-400-after-an-nft-collaboration-with-the-associated-press"
        }}>{`the hype`}</a></li>
      <li parentName="ol">{`Read `}<a parentName="li" {...{
          "href": "https://theoutline.com/post/2369/everipedia-is-the-wikipedia-for-being-wrong?zd=1&zi=76c4k5rf"
        }}>{`the issues`}</a></li>
    </ol>
    <h2 {...{
      "id": "group-work",
      "style": {
        "position": "relative"
      }
    }}>{`Group Work`}<a parentName="h2" {...{
        "href": "#group-work",
        "aria-label": "group work permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`You can follow the work we did together when in discussion `}<a parentName="p" {...{
        "href": "https://www.figma.com/file/Wq1V7DG56k16jxq0PwJsTU/Tokens-Guild%3A-EVERIPEDIA?node-id=1031%3A311"
      }}>{`on this Figma board`}</a>{`.`}</p>
    <Video src="https://www.youtube-nocookie.com/embed/JcVgs9iLoaI" mdxType="Video" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      